// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Ensure to create a CSS file for styling

import yamahaLogo from '../assets/images/yamaha_logo.svg'; // Path to Yamaha logo
import seadooLogo from '../assets/images/seadoo_logo.png'; // Path to Sea-Doo logo
import kawasakiLogo from '../assets/images/kawasaki_logo.png'; // Path to Kawasaki logo

function Home() {
  return (
    <div>
      <section className='hero'>
        <h1>Where Your Passion for Power Meets Our Excellence in Service.</h1>
        <h3>Elite Maintenance for Personal Watercraft and Jet-Boats.</h3>
        <Link to='/service-request' className='cta-button'>
          Make Your Service Request
        </Link>
      </section>

      <section className='services-overview'>
        <h2>Explore Our Specialized Services</h2>
        <p>
          At JET1 Motorsports, we offer a diverse array of specialized services
          tailored to enhance every aspect of your watercraft experience. From
          advanced on-site diagnostics to expert engine rebuilds, our team is
          dedicated to delivering excellence in maintenance, repair, and
          customization. Whether it's ensuring your jet-ski's systems are
          operating seamlessly or upgrading your ride with premium sound
          systems, our services are designed to cater to your unique needs and
          elevate your time on the water.
        </p>

        <Link to='/services' className='cta-button'>
          Discover Our Services
        </Link>
      </section>

      <section className='brands'>
        <h2>Manufacturers We Expertly Service</h2>
        <div className='brand-logos'>
          <img src={yamahaLogo} alt='Yamaha' />
          <img src={seadooLogo} alt='Sea-Doo' />
          <img src={kawasakiLogo} alt='Kawasaki' />
        </div>
      </section>

      <section className='contact-cta'>
        <h2>Get in Touch with Our Experts</h2>
        <p>
          Whether you have queries, need personalized advice, or wish to discuss
          your watercraft's needs, we're here for you. Our team is ready to
          provide you with customized solutions and expert guidance. Let's
          embark on your watercraft's journey together.
        </p>
        <Link to='/contact-us' className='cta-button'>
          Start Your Adventure
        </Link>
      </section>
    </div>
  );
}

export default Home;
