// src/components/ServiceRequestForm.js
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory
import {
  StyledButton,
  StyledForm,
  StyledLabel,
  StyledInput,
  StyledTextarea,
  StyledSelect,
} from '../StyledComponents';
import './ServiceRequestForm.css'; // Importing CSS

const API_URL = process.env.REACT_APP_API_URL;
console.log(API_URL)

function ServiceRequestForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    make: '',
    model: '',
    year: '',
    vinNumber: '',
    milesOrHours: '',
    serviceNeeds: '',
  });
  const [formErrors, setFormErrors] = useState({});

  const [isSubmitted, setIsSubmitted] = useState(false); // New state variable for submission status
  const history = useHistory(); // Instantiate useHistory
  const [validFields, setValidFields] = useState({
    email: false,
    phone: false,
    // ... other fields ...
  });

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1970; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Validate email in real-time
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setValidFields({
        ...validFields,
        email: emailRegex.test(value),
      });
    }

    // Validate phone in real-time
    if (name === 'phone') {
      const phoneRegex = /^\d{10}$/;
      setValidFields({
        ...validFields,
        phone: phoneRegex.test(value),
      });
    }

    // Validate year in real-time
    if (name === 'year') {
      setValidFields({
        ...validFields,
        year: value !== '', // Assuming any selected year is valid
      });
    }
  };

  const validateFormData = () => {
    let errors = {};

    // Validate first name
    if (!formData.firstName.trim()) {
      errors.firstName = 'First name is required';
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email.trim())) {
      errors.email = 'Please enter a valid email address';
    }

    // Validate phone
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.phone.trim())) {
      errors.phone = 'Please enter a valid phone number';
    }

    // Validate zip code (if provided)
    const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    if (
      formData.zipCode.trim() &&
      !zipCodeRegex.test(formData.zipCode.trim())
    ) {
      errors.zipCode = 'Please enter a valid zip code';
    }

    // Validate make (if provided)
    if (formData.make.trim() && !formData.make.trim()) {
      errors.make = 'Make is required';
    }

    // Validate model (if provided)
    if (formData.model.trim() && !formData.model.trim()) {
      errors.model = 'Model is required';
    }

    // Validate year (if provided)
    const yearRegex = /^\d{4}$/;
    if (
      formData.year.trim() &&
      (!yearRegex.test(formData.year.trim()) ||
        parseInt(formData.year.trim()) <= 1950)
    ) {
      errors.year = 'Please enter a valid year after 1950';
    }

    // Validate VIN number (if provided, assuming it should be 17 characters)
    if (formData.vinNumber.trim() && formData.vinNumber.trim().length !== 17) {
      errors.vinNumber = 'Please enter a valid VIN number';
    }

    // Validate miles/hours (if provided, assuming it should be a non-negative number)
    if (
      formData.milesOrHours.trim() &&
      (!/^\d+$/.test(formData.milesOrHours.trim()) ||
        parseInt(formData.milesOrHours.trim()) < 0)
    ) {
      errors.milesOrHours = 'Please enter a valid number for miles/hours';
    }

    // Validate service needs (if provided)
    if (formData.serviceNeeds.trim() && !formData.serviceNeeds.trim()) {
      errors.serviceNeeds = 'Service needs are required';
    }

    // Validate address (if serviceType is 'onSite' and address is provided)
    if (
      formData.serviceType === 'onSite' &&
      formData.address.trim() &&
      !formData.address.trim()
    ) {
      errors.address = 'Address is required for on-site service';
    }

    // Validate city (if serviceType is 'onSite' and city is provided)
    if (
      formData.serviceType === 'onSite' &&
      formData.city.trim() &&
      !formData.city.trim()
    ) {
      errors.city = 'City is required for on-site service';
    }

    // Validate state (if serviceType is 'onSite' and state is provided)
    if (
      formData.serviceType === 'onSite' &&
      formData.state.trim() &&
      !formData.state.trim()
    ) {
      errors.state = 'State is required for on-site service';
    }

    // ... additional validations ...

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateFormData()) {
      try {
        // Replace config.API_URL with API_URL
        const response = await fetch(`${API_URL}/send-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          console.log('Form Data Submitted:', formData);
          setIsSubmitted(true);

          // Log the response from the server
          const responseBody = await response.json();
          console.log('Server Response:', responseBody);

          // Redirect after submission
          setTimeout(() => {
            history.push('/services');
          }, 5000);
        } else {
          console.error('Server Error Response:', await response.text());
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    } else {
      console.log('Form Data Invalid:', JSON.stringify(formErrors, null, 2));
    }
  };

  // Add a new state variable for service type
  const [serviceType, setServiceType] = useState('');

  const handleServiceTypeChange = (event) => {
    setServiceType(event.target.value);
  };

  useEffect(() => {
    if (serviceType === 'onSite') {
      const onSiteSection = document.getElementById('onSiteSection');
      if (onSiteSection) {
        onSiteSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (serviceType === 'dropOff') {
      const dropOffMessage = document.getElementById('dropOffMessage');
      if (dropOffMessage) {
        dropOffMessage.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [serviceType]);

  // ... [Other Functions] ...

  return (
    <StyledForm onSubmit={handleSubmit}>
      <h2>Contact Infomation:</h2>
      <StyledLabel htmlFor='firstName'>First Name (required):</StyledLabel>
      <StyledInput
        type='text'
        id='firstName'
        name='firstName'
        value={formData.firstName}
        required
        onChange={handleInputChange}
        aria-describedby='firstNameError'
      />
      {formErrors.firstName && (
        <p id='firstNameError'>{formErrors.firstName}</p>
      )}

      <StyledLabel htmlFor='lastName'>Last Name:</StyledLabel>
      <StyledInput
        type='text'
        id='lastName'
        name='lastName'
        value={formData.lastName}
        onChange={handleInputChange}
        aria-describedby='lastNameError'
      />
      {formErrors.lastName && <p id='lastNameError'>{formErrors.lastName}</p>}

      <StyledLabel htmlFor='email'>Email (required):</StyledLabel>
      <StyledInput
        type='email'
        id='email'
        name='email'
        required
        autoComplete='email' // Add this line
        onChange={handleInputChange}
        aria-describedby='emailError'
        className={validFields.email ? 'valid-input' : ''}
      />
      {formErrors.email && <p id='emailError'>{formErrors.email}</p>}
      {/* ... other input fields ... */}

      <StyledLabel htmlFor='phone'>Phone (required):</StyledLabel>
      <StyledInput
        type='tel'
        id='phone'
        name='phone'
        required
        autoComplete='tel' // Add this line
        onChange={handleInputChange}
        aria-describedby='phoneError'
        className={validFields.phone ? 'valid-input' : ''}
      />
      {formErrors.phone && <p id='phoneError'>{formErrors.phone}</p>}

      {/* ... other input fields ... */}

      <h2>Vehicle Infomation:</h2>
      <StyledLabel htmlFor='make'>Make:</StyledLabel>
      <StyledInput
        type='text'
        id='make'
        name='make'
        value={formData.make}
        onChange={handleInputChange}
        aria-describedby='makeError'
      />
      {formErrors.make && <p id='makeError'>{formErrors.make}</p>}

      <StyledLabel htmlFor='model'>Model:</StyledLabel>
      <StyledInput
        type='text'
        id='model'
        name='model'
        value={formData.model}
        onChange={handleInputChange}
      />

      <StyledLabel htmlFor='year'>Year:</StyledLabel>
      <StyledSelect
        id='year'
        name='year'
        value={formData.year}
        onChange={handleInputChange}
        aria-describedby='yearError'
        className={validFields.year ? 'valid-input' : ''}
      >
        <option value=''>Select a year</option>
        {generateYearOptions().map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </StyledSelect>
      {formErrors.year && <p id='yearError'>{formErrors.year}</p>}

      <StyledLabel htmlFor='vinNumber'>VIN Number:</StyledLabel>
      <StyledInput
        type='text'
        id='vinNumber'
        name='vinNumber'
        value={formData.vinNumber}
        onChange={handleInputChange}
      />

      <StyledLabel htmlFor='milesOrHours'>Miles/Hours:</StyledLabel>
      <StyledInput
        type='text'
        id='milesOrHours'
        name='milesOrHours'
        value={formData.milesOrHours}
        onChange={handleInputChange}
      />

      {/* ... other input fields ... */}

      <h2>Service Needs:</h2>
      <StyledLabel htmlFor='serviceNeeds'>
        What kind of service do you need?
      </StyledLabel>
      <StyledTextarea
        id='serviceNeeds'
        name='serviceNeeds'
        value={formData.serviceNeeds}
        onChange={handleInputChange}
      />

      <StyledLabel htmlFor='serviceType'>
        Do you need on-site service?
      </StyledLabel>
      <StyledSelect
        id='serviceType'
        name='serviceType'
        value={serviceType}
        onChange={handleServiceTypeChange}
      >
        <option value=''>Select an option</option>
        <option value='onSite'>Yes, I need on-site service</option>
        <option value='dropOff'>No, I will drop off the watercraft</option>
      </StyledSelect>

      {serviceType === 'onSite' && (
        <div id='onSiteSection'>
          <StyledLabel htmlFor='address'>Address:</StyledLabel>
          <StyledInput
            type='text'
            id='address'
            name='address'
            value={formData.address}
            onChange={handleInputChange}
            aria-describedby='addressError'
          />
          {formErrors.address && <p id='addressError'>{formErrors.address}</p>}

          <StyledLabel htmlFor='city'>City:</StyledLabel>
          <StyledInput
            type='text'
            id='city'
            name='city'
            value={formData.city}
            onChange={handleInputChange}
            aria-describedby='cityError'
          />
          {formErrors.city && <p id='cityError'>{formErrors.city}</p>}

          <StyledLabel htmlFor='state'>State:</StyledLabel>
          <StyledInput
            type='text'
            id='state'
            name='state'
            value={formData.state}
            onChange={handleInputChange}
            aria-describedby='stateError'
          />
          {formErrors.state && <p id='stateError'>{formErrors.state}</p>}

          <StyledLabel htmlFor='zipCode'>Zip Code:</StyledLabel>
          <StyledInput
            type='text'
            id='zipCode'
            name='zipCode'
            value={formData.zipCode}
            onChange={handleInputChange}
            aria-describedby='zipCodeError'
          />
          {formErrors.zipCode && <p id='zipCodeError'>{formErrors.zipCode}</p>}
        </div>
      )}

      {serviceType === 'dropOff' && (
        <p id='dropOffMessage'>
          Thank you for choosing to drop off your watercraft. Our drop-off
          location is in Palm Bay, Florida. The exact address will be confirmed
          after we reach out to you once we receive your submitted form.
        </p>
      )}

      <StyledButton type='submit'>Submit</StyledButton>

      {/* Notification display */}
      {isSubmitted && (
        <div className='successMessage' aria-live='polite'>
          Successful Submission! Redirecting to Services Page...
        </div>
      )}
    </StyledForm>
  );
}

export default ServiceRequestForm;
