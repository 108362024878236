// src/pages/ServiceRequest.js
import React from 'react';
import ServiceRequestForm from '../components/ServiceRequestForm';
import './ServiceRequest.css';


function ServiceRequest() {
  return (
    <div className='service-request-container'>
      <h1>Service Request</h1>
      <p>Please fill out the form below to request a service.</p>
      <ServiceRequestForm />
    </div>
  );
}

export default ServiceRequest;
