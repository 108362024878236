// src/pages/Services.js
import React from 'react';
import './Services.css';

import maintenance from '../assets/photos_sent_over/maintenance.jpg'; 
import diagnostics from '../assets/photos_sent_over/diagnostics.jpg'; 
import electrical from '../assets/photos_sent_over/electrical.jpg'; 
import engine from '../assets/photos_sent_over/engine.jpg'; 
import sound from '../assets/photos_sent_over/sound.jpg'; 
import accessories from '../assets/photos_sent_over/accessories.jpg'; 

const servicesData = [
  {
    title: "On-Site Diagnostics",
    overview: "Utilizing advanced diagnostic tools and expertise to identify and resolve issues efficiently.",
    imgSrc: diagnostics
  },
  
  {
    title: "Preventative Maintenance",
    overview: "Ensure your watercraft is always in peak condition with our comprehensive preventative maintenance services.",
    imgSrc: maintenance 
  },

  {
    title: "Electrical",
    overview: "Offering a wide range of electrical services to ensure your jet ski’s systems are functioning flawlessly.",
    imgSrc: electrical 
  },
  {
    title: "Rigging",
    overview: "Expert rigging services to enhance your watercrafts’s performance and usability.",
    imgSrc: accessories 
  },
  {
    title: "Engine Rebuilds",
    overview: "Comprehensive engine rebuild services to restore and enhance your watercrafts’s performance.",
    imgSrc: engine 
  },
  {
    title: "Sound Systems",
    overview: "Elevate your riding experience with premium sound systems tailored for the marine environment.",
    imgSrc: sound 
  },
];

function Services() {
  return (
    <div className="services-container">
      <h1>Our Services</h1>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-card">
            <img src={service.imgSrc} alt={service.title} className="service-image"/>
            <h2>{service.title}</h2>
            <p>{service.overview}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
