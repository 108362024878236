// src/pages/Footer.js

import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  const emailSubject = encodeURIComponent('New Service Request Submission');
  const emailBody = encodeURIComponent(
    `Thank you for choosing JET1 Motorsports for your vehicle service needs. We are excited to assist you and ensure your vehicle receives the best possible care. To continue with your service request, please provide us with more details. This will help us understand your needs better and offer a tailored service experience.

    Kindly fill in the information below:
    
    First Name: [Enter First Name]
    Last Name: [Enter Last Name]
    Email: [Enter Email]
    Phone: [Enter Phone Number]
    Address: [Enter Address]
    City: [Enter City]
    State: [Enter State]
    Zip Code: [Enter Zip Code]
    Make of Vehicle: [Enter Make]
    Model of Vehicle: [Enter Model]
    Year of Vehicle: [Enter Year]
    VIN Number: [Enter VIN Number]
    Miles/Hours: [Enter Miles or Hours on Vehicle]
    Additionally, please describe your service needs in detail:
    
    Service Needs: [Please describe your service needs here]
    Once we get this information, our team will quickly review your request and contact you to discuss the next steps.
    
    Thank you again for choosing JET1 Motorsports. We are committed to delivering exceptional service and look forward to serving you.
`
  );

  return (
    <footer className='footer'>
      <a href={`mailto:brandon@jet1-motorsports.com?subject=${emailSubject}&body=${emailBody}`}>
        <FontAwesomeIcon className="fa-icon" icon={faEnvelope} /> {/* Email Icon */}
      </a>
      <a href='tel:+19546088021'>
        <FontAwesomeIcon className="fa-icon" icon={faPhone} /> {/* Phone Icon */}
      </a>
      <a
        href='https://www.instagram.com/jet1motorsports/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FontAwesomeIcon className="fa-icon" icon={faInstagram} /> {/* Instagram Icon */}
      </a>
    </footer>
  );
}

export default Footer;
