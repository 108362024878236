import styled from 'styled-components';

export const StyledHeader = styled.header`
display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;

  h1 {
    margin: 0;
  }

  nav {
    display: flex;
    gap: 10px;  // Space between buttons
  }

  a {
    text-decoration: none;
    color: white;
    background-color: purple;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darkmagenta;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    nav {
      margin-top: 10px;
    }
  }
`;

export const StyledNav = styled.nav`
  a {
    color: #61dafb;
    margin: 0 15px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
  margin-bottom: 100px; /* Adjust as needed */
`;

export const StyledLabel = styled.label`
  margin-bottom: 5px;
`;

export const StyledInput = styled.input`
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
`;

export const StyledTextarea = styled.textarea`
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  resize: vertical;
`;

export const StyledButton = styled.button`
  background-color: #61dafb;
  color: #282c34;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
  background-color: #21a1f1;
  }
`;
// Form Submit Button Setting

export const StyledFooter = styled.footer`
  background-color: #282c34;
  color: white;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  p {
    margin: 5px;
  }
  a {
    color: #ff4500;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledSelect = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;
