import React from 'react';
import './ContactUs.css';
import { Link } from 'react-router-dom';
import { FaInstagram, FaPhone, FaEnvelope } from 'react-icons/fa'; // Assuming you've installed react-icons

function ContactUs() {
  return (
    <div className='contact-us-container'>
      <h1 className='contact-us-header'>Contact Us</h1>
      <div className='business-card'>
        <h2>Brandon Villaquiran</h2>

        <div className='contact-info'>
          <p>
            <FaEnvelope className="icon" />
            <a href='mailto:brandon@jet1-motorsports.com'>brandon@jet1-motorsports.com</a>
          </p>
          <p>
            <FaPhone className="icon" />
            <a href="tel:+1-954-608-8021">(954) 608-8021</a>
          </p>
          <p>
            <FaInstagram className="icon" />
            <a href='https://www.instagram.com/jet1motorsports/' target='_blank' rel='noopener noreferrer'>
              @jet1motorsports
            </a>
          </p>
        </div>
      </div>
      <Link to='/service-request' className='cta-button'>
        Submit Your Service Request
      </Link>
    </div>
  );
}

export default ContactUs;
