// src/pages/Products.js
import React from 'react';
import './Products.css';

// Import images for each product
import performancePackage from '../assets/photos_sent_over/performancepackage.jpg'; // Adjust the path and filename as necessary
import performanceEngine from '../assets/photos_sent_over/performanceEngine.jpeg';
import handlingKit from '../assets/photos_sent_over/handlingKit.jpg';
import ecuUpgrade from '../assets/photos_sent_over/ecuUpgrade.jpg';
import protectiveGear from '../assets/photos_sent_over/protectiveGear.jpg';
import navigationElectronics from '../assets/photos_sent_over/navigationElectronics.jpg';

// import product2 from '../assets/photos_sent_over/product2.jpg';
// ... import other product images ...

// Products data
const productsData = [
  {
    title: "JET1 Performance Packages",
    description: "Elevate your jetski's power with our Performance Package – a proven combination for enhanced performance and reliability.",
    imgSrc: performancePackage
  },
  {
    title: "Performance Engines & Components",
    description: "High-performance engines and components for speed and power enhancement. Designed for optimal speed and power, these parts deliver exceptional performance enhancements.",
    imgSrc: performanceEngine
  },
  {
    title: "Handling Kits",
    description: "Revamp your watercraft's maneuverability and stability with our advanced handling kits. These kits, featuring sponsons and steering systems, are engineered for precise control and smoother rides.",
    imgSrc: handlingKit
  },
  {
    title: "ECU Upgrades",
    description: "Unleash the full potential of your jetski with our cutting-edge ECU upgrades. They optimize your watercraft's performance and fuel efficiency, ensuring a superior riding experience.",
    imgSrc: ecuUpgrade
  },
  {
    title: "Protective Gear",
    description: "Shield your jetski from the rigors of the water with our durable protective gear. Our range includes covers, bumpers, and hull protection, designed to prevent impacts and wear.",
    imgSrc: protectiveGear
  },
  {
    title: "Navigation and Electronics",
    description: "Navigate with confidence and enjoy premium entertainment with our advanced navigation and electronic systems. Our selection includes GPS systems, fish finders, and waterproof audio systems tailored for jetskis.",
    imgSrc: navigationElectronics
  },

  // ... other products ...
];

function Products() {
  return (
    <div className="products-container">
      <h1>Our Products</h1>
      <div className="products-grid">
        {productsData.map((product, index) => (
          <div key={index} className="product-card">
            <img src={product.imgSrc} alt={product.title} className="product-image"/>
            <h2>{product.title}</h2>
            <p>{product.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;
