// src/components/Header.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import logo from '../assets/images/logo.png';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <header className='header'>
      <NavLink to='/' className='logo' onClick={handleLinkClick}>
        <img src={logo} alt='JET1MOTORSPORTS' />
      </NavLink>
      <NavLink to='/' className='logo' onClick={handleLinkClick}></NavLink>
      <nav className={`navigation ${isOpen ? 'open' : ''}`}>
        <NavLink
          to='/services'
          activeClassName='active-link'
          onClick={handleLinkClick}
        >
          Services
        </NavLink>
        <NavLink
          to='/products'
          activeClassName='active-link'
          onClick={handleLinkClick}
        >
          {' '}
          {/* Add this NavLink */}
          Products
        </NavLink>
        <NavLink
          to='/service-request'
          activeClassName='active-link'
          onClick={handleLinkClick}
        >
          Service Request
        </NavLink>
        <NavLink
          to='/contact-us'
          activeClassName='active-link'
          onClick={handleLinkClick}
        >
          Contact Us
        </NavLink>
      </nav>
      <button
        className={`menu-btn ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
  );
}

export default Header;
