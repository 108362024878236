// src/components/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from '../pages/Home';
import Services from '../pages/Services';
import Products from '../pages/Products'; // Import the Products component
import ServiceRequest from '../pages/ServiceRequest';
import ContactUs from '../pages/ContactUs';
import NotFound from '../pages/NotFound';
import './App.css';

function App() {
  return (
    <Router>
      <div className='App'>
        <Header />
        <div className='App-content'>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/services' component={Services} />
            <Route path='/products' component={Products} /> {/* Add the Products route */}
            <Route path='/service-request' component={ServiceRequest} />
            <Route path='/contact-us' component={ContactUs} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
